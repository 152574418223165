/**
 * formats string in start case without removing dashes
 * @param {string} string
 * @returns {string}
 */

export const customStartCase = (string) => {
    // Handle camelCase and add spaces before uppercase letters not following another uppercase
    let step1 = string.replace(/(?<!^)([A-Z][a-z]|(?<=[a-z])[A-Z])/g, ' $1');

    // Capitalize hyphenated words as a whole
    let step2 = step1.replace(/(\b[a-z])/g, function(letter) {
        return letter.toUpperCase();
    });

    // Ensure both parts of hyphenated words are properly capitalized
    let result = step2.replace(/([A-Z][a-z]*)-([a-z]+)/g, function(match, p1, p2) {
        return p1 + '-' + p2.charAt(0).toUpperCase() + p2.slice(1);
    });

    return result.trim();
}