import { Form, Formik } from "formik"
import { graphql, navigate } from "gatsby"
import { getSrc } from "gatsby-plugin-image"
import _isEmpty from "lodash.isempty"
import _startCase from "lodash.startcase"
import React from "react"
import { AiOutlineUser, AiTwotoneTags } from "react-icons/ai"
import { FaMapMarkerAlt } from "react-icons/fa"
import { GiEnvelope, GiPaperPlane, GiSmartphone } from "react-icons/gi"
import styled, { css } from "styled-components"
import theme from "styled-theming"
import * as Yup from "yup"
import { MyTextInput, themedSubmit } from "../../components/ContactForm"
import { Container } from "../../components/containers/Container"
import ImageCarousel from "../../components/ImageCarousel"
import Layout from "../../components/layout"
import { PageTitle } from "../../components/pagebuilder-parts"
import Phone from "../../components/Phone"
import Seo from "../../components/Seo"
import UniversalLink from "../../components/UniversalLink"
import { colors, device, zIndex } from "../../styles/theme"
import { boldText } from "../../utils/boldText"
import { CreateLocalLink } from "../../utils/createLocalLink"
import { encodeFormData } from "../../utils/encodeFormData"
import Button from "../../components/Button"
import { SignalCellularNull } from "@material-ui/icons"
import { customStartCase } from "../../utils/customStartCase"


const themedInventoryItem = theme("mode", {
  light: css`
    aside {
      background-color: ${colors.offWhite};
    }

    summary,
    a {
      color: ${colors.primary.default};
    }
  `,
  dark: css`
    aside {
      background-color: ${colors.grayscale.light1};
    }
    summary,
    a {
      color: ${colors.primary.lighter};
    }
  `,
})

const themedPrice = theme("mode", {
  light: css`
    h4:first-child,
    h4:first-child span {
      color: ${colors.danger.default};
    }
    h4:nth-child(2),
    h4:nth-child(2) span {
      color: ${colors.primary.default};
    }

    .price-wrapper > :first-child {
      color: ${colors.black};
    }
  `,
  dark: css`
    h4:first-child,
    h4:first-child span {
      color: ${colors.accentLight.default};
    }
    h4:nth-child(2),
    h4:nth-child(2) span {
      color: ${colors.primary.light};
    }
    .price-wrapper > :first-child {
      color: ${colors.white};
    }
  `,
})

const StyledContainer = styled(Container)`
  ${themedInventoryItem};
  display: grid;
  grid-gap: 2rem;

  @media ${device.lg} {
    grid-template-columns: 50vw 1fr;
  }
  @media ${device.xl} {
    grid-template-columns: 40vw 1fr;
  }

  > section {
    position: relative;

    &:last-child {
  

      @media ${device.md} {
        justify-items: center;
        align-items: center;
        grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
      }
    }
  }

  > div {
    grid-column: 1/-1;
    text-align: center;
    display: flex;
    flex-direction: column;

    p {
      font-size: calc(var(--default-font-size) / 1.25);
      font-weight: 500;
    }

    > :last-child {
      align-self: flex-start;
    }
  }

  .sale-item {
    background: hsla(0, 50%, 43%, 1);
    opacity: 1;
    color: yellow;
    top: 10px;
    right: -10px;
    position: absolute;
    height: 15rem;
    width: 15rem;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    z-index: ${zIndex.elevated};
    pointer-events: none;
    h4,
    p,
    span {
      color: ${colors.white};
    }
    p {
      letter-spacing: 2px;
      font-size: 0.8em;
      margin: 0;
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7);
    }

    h4 {
      line-height: 1;
      top: 0;
      margin: 0;
      margin-bottom: 0.125em;
      text-transform: uppercase;
      font-family: var(--font-stack-title);
      letter-spacing: 3px;
      font-size: 2em;
      font-weight: 400;
      text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.9),
        2px 2px 4px rgba(0, 0, 0, 0.7);
    }
    span {
      font-weight: bold;
      font-family: var(--font-stack-body);
      margin: 0;
      font-size: 1.6rem;
    }
  }

  aside {
    padding: 2rem;
    border-radius: 1rem;
    box-shadow: 0 1rem 4rem rgba(0, 0, 0, 0.3);
    justify-self: stretch;

    h6,
    p {
      margin: 0;
    }

    ul {
      li {
        display: flex;
        &:not(:last-child) {
          align-items: center;
        }
        > svg {
          color: ${colors.accentDark.default};
          font-size: 2.4rem;
          margin-right: 1rem;
        }

        > details {
          p {
            margin-bottom: 2rem;
            margin-left: -4rem;
          }
        }
      }
      li:not(:last-child) {
        margin-bottom: 1rem;
      }
    }

    details {
      width: 100%;
      p {
        text-align: center;
      }
    }
    summary {
      margin-bottom: 1rem;
    }
  }
`

const ShedInfo = styled.div`
  ${themedPrice};
  h4 {
    &,
    span {
      font-size: 2rem;
    }

    svg {
      margin-right: 1rem;
    }
    span {
      margin-left: 0.5rem;
    }
    font-family: var(--font-stack-body);
    font-style: italic;
    margin: 0;
    margin-bottom: 1rem;
    display: flex;
    align-items: flex-end;
  }

  .price-wrapper {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;

    > :first-child {
      text-decoration: line-through;
    }
  }

  ul {
    margin: 0;
    margin-top: 2rem;
    padding: 0;
    list-style: none;

    li {
      font-weight: 400;
      /* letter-spacing:1px; */
      span {
        font-weight: 600;
      }

      p {
        display: inline-block;
        margin: 0;
      }

      text-transform: capitalize;
    }
  }
`

const StyledForm = styled(Form)`
  display: grid;
  grid-gap: 1rem;
  margin-left: -4rem;

  button {
    outline: none;
    ${themedSubmit};
    padding: 1rem 2rem;
    justify-self: flex-end;
    border: none;
    svg {
      margin-right: 1rem;
      color: inherit;
    }

    &.disabled {
      pointer-events: none;
    }
  }
`

const InformationSection = styled.section`
  display: grid;
  grid-gap: 2rem;

  .buttonWrapper {
    margin: 1em;
  }
`

const InventoryItem = ({
  data: {
    title,
    inventoryItem: {
      itemLocation,
      featuredImage: { node },
      inventoryItemDetails: {
        saleItem,
        shedStatus,
        shedInfo: {
          discountAmount,
          price,
          serial,
          shedDescription,
          size,
          style,
          productLine,
          tagId,
          colors: { siding, trim, roof },
          lotNumber,
        },
        shedGallery,
        shedLocation,
      },
    },
  },
  location,
}) => {

  const styleLinkDict = {
    "premiumWoodTennessee":{
      "utility": "https://3dsheddesign.watsonbarnrentals.com/#fa10a1891add12273b37021ce7ef398e",
      "gardenShed": "https://3dsheddesign.watsonbarnrentals.com/#f307696d5cdf1f1ce39883212a94afef",
      "garage": "https://3dsheddesign.watsonbarnrentals.com/#8b0c8090694e9a046d58f154dce565a8",
      "loftedBarn": "https://3dsheddesign.watsonbarnrentals.com/#c91a2b273bb23955e17fce85f7c4654e",
    },
    "premiumWoodAlabma":{
      "utility": "https://3dsheddesign.watsonbarnrentals.com/#6ba1d4b7102e08f228557cf219e758e4",
      "gardenShed": "https://3dsheddesign.watsonbarnrentals.com/#c742fc1382833af8e0c7622020786936",
      "garage": "https://3dsheddesign.watsonbarnrentals.com/#b179d9a371d90aae95c221ae8de68b07",
      "cabin": "https://3dsheddesign.watsonbarnrentals.com/#e98aa6a335faec9a4e43e3ef423d4693",
    },
    "classicWood":{
      "utility": "https://3dsheddesign.watsonbarnrentals.com/?ignorezip=true#ee434927562bf30aea7b0cee90b65238",
      "gardenShed": "https://3dsheddesign.watsonbarnrentals.com/?ignorezip=true#49b4baa32bf29d55644af980a8c21172",
      "garage": "https://3dsheddesign.watsonbarnrentals.com/?ignorezip=true#c6edea80828261b7086bcf3b6b4ea2c1",
      "cabin": "https://3dsheddesign.watsonbarnrentals.com/?ignorezip=true#8ab3cdf70d97af68eedc1f0a924acdce",
      "deluxeCabin": "https://3dsheddesign.watsonbarnrentals.com/?ignorezip=true#458c4ba08363e79f73a05e8b37a7ae35",
      "loftedBarn": "https://3dsheddesign.watsonbarnrentals.com/?ignorezip=true#981b2dcd7320668fda80a8c2b79d1107",
      "sideloftedBarn": "https://3dsheddesign.watsonbarnrentals.com/?ignorezip=true#46b7275514ea0aaf329984da9a584c8d",
      "loftedGarage": "https://3dsheddesign.watsonbarnrentals.com/?ignorezip=true#d3004ecc871c53c203ffd942fa573500",
      "loftedCabin": "https://3dsheddesign.watsonbarnrentals.com/?ignorezip=true#f7681a320f4f30eacde3f6229db67cf0",
      "deluxeLoftedCabin": "https://3dsheddesign.watsonbarnrentals.com/?ignorezip=true#2391a504aa4fec6479a73d7a13e7e15e",
    },
    "classicMetal":{
      "utility": "https://3dsheddesign.watsonbarnrentals.com/?ignorezip=true#d7c06969e46a3b5a05ea063150c74ff4",
      "gardenShed": "https://3dsheddesign.watsonbarnrentals.com/?ignorezip=true#43a23d2697064c3647243c4ac2a1d28b",
      "garage": "https://3dsheddesign.watsonbarnrentals.com/?ignorezip=true#a38582a25c426ac2535cfa66972f5a4a",
      "dutch": "https://3dsheddesign.watsonbarnrentals.com/?ignorezip=true#fe61b27aa54d16fa568717a1ccc8c40f",
      "loftedBarn": "https://3dsheddesign.watsonbarnrentals.com/?ignorezip=true#6d9cad0e8ea425a73ef3bb99c5fe69b1",
      "sideloftedBarn": "https://3dsheddesign.watsonbarnrentals.com/?ignorezip=true#ee476ee5ed7cebaa19de651b57715982",
      "loftedGarage": "https://3dsheddesign.watsonbarnrentals.com/?ignorezip=true#ee476ee5ed7cebaa19de651b57715982",
    },
    "valueMetalTennessee":{
      "utility": "https://3dsheddesign.watsonbarnrentals.com/#88c424dc87a280c5c90603d4ae02e57e",
    },
    "valueMetalAlabama":{
      "utility": "https://3dsheddesign.watsonbarnrentals.com/#31aded8ce2bccd0adb41302cb9a083e1",
    },
  }

  let styleLink = null
  if (styleLinkDict[productLine]) {
    styleLink = styleLinkDict[productLine][style] || null
  }

  const validationSchema = Yup.object({
    fullName: Yup.string()
      .required("We like to know who we're talking to. :)")
      .min(3, "Name must be at least 3 characters.")
      .max(30, "Name is too long!"),
    email: Yup.string().email().required(`We'll never share your email.`),
    phone: Yup.string()
      .min(7)
      .required("Required")
      .matches(
        /((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}/,
        "Phone must match the format xxx-xxx-xxxx"
      ),
    serial: Yup.string(),
    salesPerson: Yup.string(),
  })
  const {
    salesLotInfo: {
      pageTitle,
      locationInfo: { state, city, zipcode },
    },
  } = shedLocation[0]

  const seoBannerUrl = getSrc(node?.localFile.childImageSharp?.gatsbyImageData)

  return (
    <Layout>
      <Seo
        title={`${size} ${customStartCase(style)} - ${serial}`}
        banner={seoBannerUrl}
        desc={`${size} ${productLine} ${_startCase(
          style
        )} - ${shedDescription}. View more info...`}
        keywords={`${size}, ${_startCase(
          style
        )}, ${productLine},${pageTitle}, ${city}, ${state}, ${zipcode}, rent to own, rent-to-own, rto`}
      />
      <PageTitle
        backgroundColor="light"
        title={`${size} ${_startCase(productLine)} ${customStartCase(style)}`}
        location={location}
        itemLocation={itemLocation}
      />
      <StyledContainer>
        <div>
          <p>
            (Pricing does NOT includes Sales Tax. Sales Tax is added to all
            sales.)
            <br />
            Used buildings are sold AS IS!!
          </p>
          <UniversalLink
            to={CreateLocalLink(`locations/${itemLocation}/inventory`)}
          >{`Back to all ${_startCase(itemLocation)} Sheds`}</UniversalLink>
        </div>
        <section>
          <ImageCarousel imageGallery={shedGallery} lotNumber={lotNumber}/>
          {saleItem && saleItem === "Yes" && (
            <div className="sale-item">
              <h4>
                Sale
              </h4>
              <p>
                <span>${discountAmount}</span> off!
              </p>
            </div>
          )}
        </section>
        <InformationSection>
          <div>
            {styleLink != null ?
            <div className="buttonWrapper">
              {styleLink == null ? null : <Button text="Design this style in 3D" uri={styleLink} configuratorLink={true} />}
            </div>
            : null}
            <ShedInfo>
              <h4>
                <AiTwotoneTags /> Sale Price:{" "}
                {saleItem && saleItem === "Yes" && (
                  <span className="price-wrapper">
                    <span>${Number(price).toFixed(2)}</span>
                    <span>${`${Number(price - discountAmount).toFixed(2)}`}</span>
                  </span>
                )}
                <span>{saleItem === "No" ? `$${price} ` : ""} + tax</span>
              </h4>
              <h4>
                <AiTwotoneTags /> 36 Month RTO:{" "}
                <span>
                  $
                  {`${(
                    Number(saleItem === "Yes" ? price - discountAmount : price) /
                    21.6
                  ).toFixed(2)} + tax`}
                </span>
              </h4>
              <ul>
                <li
                  dangerouslySetInnerHTML={{
                    __html: `<span>Product Line: </span> ${_startCase(productLine)}`,
                  }}
                />
                <li
                  dangerouslySetInnerHTML={{
                    __html: `<span>Siding Color: </span> ${siding}`,
                  }}
                />
                <li
                  dangerouslySetInnerHTML={{
                    __html: `<span>Trim Color: </span> ${trim}`,
                  }}
                />
                <li>
                  <span>Roof Color: </span>
                  {roof}
                </li>
              </ul>
              <ul>
                <li>
                  <span>Size: </span>
                  {`${size} ${customStartCase(style)}`}
                </li>
                <li>
                  <span>Serial: </span>
                  {serial}
                </li>
                <li>
                  <span>Tag ID: </span>
                  {tagId}
                </li>
                <li>
                  <span>Description: </span>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: `${boldText(shedDescription)}`,
                    }}
                  />
                </li>
                <li>
                  <span>New/Used: </span>
                  {shedStatus}
                </li>
              </ul>
            </ShedInfo>
          </div>
          <aside>
            <h6>Find this shed at:</h6>
            {shedLocation.map((location) => {
              const {
                title,
                salesLotInfo: {
                  pageTitle,
                  locationInfo: {
                    streetAddress,
                    state,
                    city,
                    zipcode,
                    phoneNumber,
                  },
                },
                salesPeople: {
                  salesPerson: {
                    0: { memberName },
                  },
                },
              } = location
              return (
                <React.Fragment key={pageTitle}>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: `${pageTitle} (${title})`,
                    }}
                  />
                  <ul className="fa-ul">
                    <li>
                      <AiOutlineUser color={colors.danger.default} />
                      {memberName}
                    </li>
                    <li>
                      <FaMapMarkerAlt  color={colors.danger.default} />
                      <p
                        dangerouslySetInnerHTML={{
                          __html: `${streetAddress} <br /> ${city}, ${state} ${zipcode}`,
                        }}
                      />
                    </li>
                    <li>
                      <GiSmartphone  color={colors.danger.default} />
                      <Phone phone={phoneNumber} />
                    </li>
                    <li>
                      <GiEnvelope  color={colors.danger.default} />
                      <details>
                        <summary>Contact me about this shed!</summary>
                        <p>
                          Leave your contact info and I'll be in touch shortly!
                        </p>
                        <Formik
                          initialValues={{
                            fullName: "",
                            phone: "",
                            email: "",
                            serial: serial,
                            salesPerson: memberName,
                          }}
                          onSubmit={(data, { setSubmitting, resetForm }) => {
                            setSubmitting(true)
                            //make async call
                            fetch("/", {
                              method: "POST",
                              headers: {
                                "Content-Type":
                                  "application/x-www-form-urlencoded",
                              },
                              body: encodeFormData({
                                "form-name": "shed-inquiry",
                                ...data,
                              }),
                            })
                              .then(() =>
                                navigate("/thank-you", { replace: true })
                              )
                              .catch((error) =>
                                alert(
                                  "Oops.. Something went wrong. Please contact us with this error message: " +
                                    error
                                )
                              )
                            resetForm()
                            setSubmitting(false)
                          }}
                          validationSchema={validationSchema}
                        >
                          {({ isSubmitting, errors, values }) => {
                            return (
                              <StyledForm
                                name="shed-inquiry"
                                data-netlify="true"
                                netlify-honeypot="bot-field"
                                method="POST"
                              >
                                <input
                                  type="hidden"
                                  name="serial"
                                  value={serial}
                                />
                                <input
                                  type="hidden"
                                  name="salesPerson"
                                  value={memberName}
                                />
                                <input
                                  type="hidden"
                                  name="form-name"
                                  value="shed-inquiry"
                                />
                                <input type="hidden" name="bot-field" />
                                <MyTextInput
                                  id="fullName"
                                  name="fullName"
                                  label="Full Name"
                                  type="text"
                                  placeholder="Full Name"
                                />
                                <MyTextInput
                                  id="phone"
                                  name="phone"
                                  label="Phone"
                                  type="text"
                                  placeholder="Phone"
                                />
                                <MyTextInput
                                  id="email"
                                  name="email"
                                  label="Email"
                                  type="text"
                                  placeholder="Email"
                                />
                                <button
                                  className={
                                    isSubmitting || !_isEmpty(errors)
                                      ? "disabled"
                                      : ""
                                  }
                                  disabled={isSubmitting || !_isEmpty(errors)}
                                  type="submit"
                                >
                                  <GiPaperPlane />
                                  Submit
                                </button>
                              </StyledForm>
                            )
                          }}
                        </Formik>
                      </details>
                    </li>
                  </ul>
                </React.Fragment>
              )
            })}
          </aside>
        </InformationSection>
      </StyledContainer>
    </Layout>
  )
}
export default InventoryItem
export const query = graphql`
  query inventoryItem($id: String!) {
    inventoryItem: wpInventoryItem(id: { eq: $id }) {
      title
      id
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      itemLocation
      inventoryItemDetails {
        saleItem
        shedStatus
        shedInfo {
          discountAmount
          price
          serial
          shedDescription
          productLine
          size
          sizeAsInt
          style
          tagId
          colors {
            roof
            siding
            trim
          }
          lotNumber
        }
        shedGallery {
          id
          altText
          fullImage: localFile {
            ...HeroImage
          }
          fixedImage: localFile {
            ...FixedImage
          }
        }
        shedLocation {
          __typename
          ... on WpLocation {
            title
            salesPeople {
              salesPerson {
                memberName
              }
              memberContact {
                email
              }
            }
            salesLotInfo {
              pageTitle
              locationInfo {
                streetAddress
                city
                zipcode
                state
                phoneNumber
              }
            }
          }
        }
      }
    }
  }
`
